<template>
    <section>
      <div class="bg-white mt-2">
        <div class="p-4 border-b border flex justify-between">
          <div class="flex items-center text-gray-600 gap-x-2">
            <i class="pi pi-lock"></i>
            <p class="text-lg font-bold">Autorizaciones</p>
          </div>
          <div class="flex justify-end gap-x-2">
            <Button @click="guardarPermisos" class="lg:w-40" icon="pi pi-save" label="Guardar" />
          </div>
        </div>
        <div class="w-full mx-2 flex">
          <div class="lg:w-80">
            <div class="m-2">
              <Dropdown class="w-full" @change="buscarGrupo" v-model="grupoBuscado" :options="listadoGrupos" optionLabel="name" :filter="true" placeholder="Buscar" :showClear="true"></Dropdown>
            </div>
            <div class="w-full overflow-y-auto px-2" style="height: 80vh;">
              <div v-for="(grupo, i) in gruposBuscados.length ? gruposBuscados : listadoGrupos" :key="i">
                <div class="p-4 my-2" :class="grupoSeleccionado.id === grupo.id ? 'bg-blue-200' : 'bg-gray-200'">
                  <input class="p-radiobutton" @click="listarPermisosPorGrupo(grupo.id)" :inputId="grupo" name="name" :value="grupo.id" v-model="grupoSeleccionado" type="radio">
                  <label class="mx-4">{{grupo.name}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full mx-4">
            <Permisos  :nivel="1" :listadoServicios="listadoServicios" />
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>
  import { ref, onMounted, defineAsyncComponent, computed } from 'vue'
  import GruposService from '../../../../../services/grupos.service'
  import PermisosService from '../../../../../services/permisos.service'
  import permisosStore from '../../../../../stores/permisos.store'
  import { useRouter } from 'vue-router'
  import Swal from 'sweetalert2'
  export default {
    name: 'permisosGrupo',
    components: {
      Permisos: defineAsyncComponent(() => import('../../../pages/usuarios/crear/components/permisos.vue'))
    },
    setup () {
      // Services
      const _GruposService = ref(new GruposService())
      const _PermisosService = ref(new PermisosService())
      const router = useRouter()
      // References
      const listadoGrupos = ref([])
      const grupoSeleccionado = ref(1)
      const listadoPermisos = ref([])
      const grupoBuscado = ref({})
      const gruposBuscados = ref([])

      const listadoServicios = computed(() => permisosStore.getters._listadoServicios)

      const permisosPorGuardar = computed(() => permisosStore.getters._permisosGuardar)
      // Data
      const autorizaciones = ref([
        {
          nombre: 'Autorizacion total',
          value: 1,
          class: 'text-blue-600'
        },
        {
          nombre: 'Varias autorizaciones',
          value: 2,
          class: 'text-blue-200'
        },
        {
          nombre: 'Falta autorización',
          value: 3,
          class: 'text-gray-500'
        }
      ])
      // Methods
      const listarGrupos = () => {
        _GruposService.value.getall().then(({ data }) => {
          listadoGrupos.value = data
          permisosStore.commit('setGrupoSelecionado', 1)
          permisosStore.dispatch('listarPermisosPorGrupo')
        })
      }
      const buscarGrupo = () => {
        if (grupoBuscado.value) {
          grupoSeleccionado.value = grupoBuscado.value.id
          listarPermisosPorGrupo(grupoSeleccionado.value)
          gruposBuscados.value = listadoGrupos.value.filter(x => x.name === grupoBuscado.value.name)
        } else {
          gruposBuscados.value = []
        }
      }
      const listarPermisosPorGrupo = (grupo) => {
        permisosStore.commit('setGrupoSelecionado', grupo)
        permisosStore.dispatch('listarPermisosPorGrupo')
      }
      const guardarPermisos = () => {
        if (permisosPorGuardar.value.length) {
          _PermisosService.value.postPermisosGrupo(permisosPorGuardar.value).then((data) => {
            if (data.error) {
              Swal.fire(
                'Error',
                'Error al guardar permisos, intente nuevamente',
                'error'
              ).then(() => {
              })
            } else {
              Swal.fire(
                'Exito',
                'Permisos guardados satisfactoriamente',
                'success'
              ).then(() => {
                router.push({ name: 'usuarios.grupos' })
              })
            }
          })
        } else {
          Swal.fire(
            'Advertencia',
            'Debes seleccionar al menos un permiso para continuar',
            'warning'
          )
        }
      }
      onMounted(() => {
        listarGrupos()
      })
      return {
        listadoGrupos,
        grupoSeleccionado,
        listarPermisosPorGrupo,
        listadoPermisos,
        autorizaciones,
        guardarPermisos,
        permisosPorGuardar,
        buscarGrupo,
        gruposBuscados,
        grupoBuscado,
        listadoServicios
      }
    }
  }
  </script>

  <style lang="scss" scoped>
    ::-webkit-scrollbar {
      width: 0.2rem;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(248, 248, 248);
      border-radius: 9999px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgb(207, 207, 207);
    }
    .selectButtonPermissions {
      ::v-deep(.p-checkbox .p-checkbox-box) {
        border-radius: 50%;
      }
      ::v-deep(.p-selectbutton .p-button.p-highlight){
        background: white;
        color: #1C3FAA;
        width: 100%;
        font-weight: 500;
        font-size: 1rem;
        border: 1px solid rgb(28, 63, 170);
        margin-top: 0.5rem;
     }
     ::v-deep(.p-selectbutton .p-button.p-highlight:hover){
        background: white;
        color: #1C3FAA;
        width: 100%;
        font-weight: 500;
        font-size: 1rem;
        border: 1px solid rgb(28, 63, 170);
        margin-top: 0.5rem;
     }
     ::v-deep(.p-selectbutton .p-button){
        background: #F6F6F6;
        color: #7F7E81;
        width: 100%;
        font-size: 1rem;
        margin-top: 0.5rem;
        border: transparent;
      }
     ::v-deep(.p-selectbutton .p-button:hover){
        background: #F6F6F6;
        color: #7F7E81;
        width: 100%;
        font-size: 1rem;
        margin-top: 0.5rem;
        border: transparent;
     }
     ::v-deep(.p-dropdown .p-dropdown-label){
        font-size: 0.4rem !important;
        padding: 0.2rem !important;
     }
     :global(.swal2-container) {z-index: 1000000 !important}
    }
  </style>
